@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    .navbar {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.navbar {
    padding-left: 1%;
    padding-right: 1%;
    /* border-bottom-right-radius:10px;
    border-bottom-left-radius:10px; */
    font-family: "Lobster", cursive !important;
}

.navbar-nav > .active > a {
    color: aqua;
    background-color: chartreuse;    
}

.nav-item > a:hover {
    color: aqua;
}

.marginForNavbar {
    margin-left: 2%;
}

#alignSwitch {
    display: flex;
    vertical-align: middle;
    /* justify-content: center;
    margin: 0 2%; */
}

#alignSwitch > * {
    margin-left: 10px;
}