.Main {
    background-color: white;
}

th {
    font-size: large;
    border-color: black !important;
    vertical-align: middle;
}

thDark {
    background-color: #6c757d !important;
}

thNormal {
    background-color: #6c757d !important;
}

tr:hover {
    /* cursor: pointer; */
    opacity: 0.5;
}

.dateAndNumberClass {
    text-align: center;
}

.ligneClasse, .ligneClassePerime {
    cursor: pointer;
}

.ligneClassePerime {
    color: red !important;
}

.formSearchInput {
    padding-left : 2%;
    padding-right : 2%;
    /* padding-top : 2%; */
    padding-bottom : 1%;
}

.validButton {
    margin-left: 0 !important;
}

.formDivNormal {
    background-color: #f8f9fa !important;
}

.formDivDark {
    background-color: #212529 !important;
    color: white;
}
