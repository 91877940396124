@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
/* @import url('./lobsterCss.css'); */

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: "Lobster", cursive !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100% !important;
    overflow: auto !important;
    background-color: white !important;
    margin-top: 60px !important;
    z-index: 0 !important;
    
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.mainDiv, .mainDivDark {
    /* margin: 2%; */
    /* text-align: center; */
}